<template>
  <LayoutVisiteur>
    <section>
      <div class="container-fluid">
        <div class="row g-4 p-2 py-4">
          <div class="col-lg-8 col-12" v-if="!loading">
            <div
              class="p-3 border-0 bg-light text-dark rounded container-fluid"
            >
              <!-- sticky -->
              <!-- class="p-3 border-0 bg-light text-dark rounded container-fluid sticky-top"
              style="top: 5.6rem; z-index: 0" -->
              <div>
                <h1 class="text-primary">
                  {{ data.title_article }}
                </h1>
                <div
                  class="d-flex my-3 text-muted align-items-center justify-content-between"
                >
                  <small>Par {{ data.fname_collab }}</small>
                  <small
                    >Posté le
                    {{ moment(data.date_article).format("LL") }}</small
                  >
                </div>
                <article class="content-article w-100">
                  <div v-html="data.content_article"></div>
                </article>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-12" v-if="loading">
            <vue-content-loading :width="400" :height="65">
              <rect x="10" y="17" rx="3" ry="3" width="125" height="6" />
              <rect x="10" y="32" rx="3" ry="3" width="50" height="6" />
              <rect x="10" y="47" rx="3" ry="3" width="100" height="6" />
              <rect x="330" y="47" rx="3" ry="3" width="55" height="6" />
            </vue-content-loading>
            <vue-content-loading :width="400" :height="130">
              <rect x="10" y="0" rx="3" ry="3" width="120" height="120" />
              <rect x="143" y="2" rx="3" ry="3" width="210" height="6" />
              <rect x="143" y="17" rx="3" ry="3" width="190" height="6" />
              <rect x="142" y="32" rx="3" ry="3" width="195" height="6" />
              <rect x="141" y="47" rx="3" ry="3" width="220" height="6" />
              <rect x="143" y="62" rx="3" ry="3" width="170" height="6" />
              <rect x="145" y="77" rx="3" ry="3" width="215" height="6" />
              <rect x="142" y="92" rx="3" ry="3" width="245" height="6" />
              <rect x="144" y="107" rx="3" ry="3" width="222" height="6" />
            </vue-content-loading>
            <vue-content-loading :width="400" :height="90">
              <rect x="10" y="0" rx="3" ry="3" width="370" height="6" />
              <rect x="10" y="17" rx="3" ry="3" width="385" height="6" />
              <rect x="10" y="32" rx="3" ry="3" width="360" height="6" />
              <rect x="10" y="47" rx="3" ry="3" width="375" height="6" />
              <rect x="10" y="62" rx="3" ry="3" width="372" height="6" />
              <rect x="10" y="77" rx="3" ry="3" width="380" height="6" />
            </vue-content-loading>
          </div>
          <div class="col-lg-4 col-12" v-if="!loading">
            <div class="p-3 border-0 text-dark rounded">
              <div v-if="loading">Chargement des commentaires</div>
              <Commentaire
                v-else
                :commentaires="data.commentaires"
                :id_article="data.id_article"
              />
            </div>
          </div>
          <div class="col-lg-4 col-12" v-if="loading">
            <div class="mt-4">
              <vue-content-loading
                v-for="index in 3"
                :key="index"
                :width="400"
                :height="160"
              >
                <rect x="10" y="15" rx="8" ry="8" width="370" height="60" />
                <rect x="30" y="95" rx="8" ry="8" width="350" height="60" />
              </vue-content-loading>
            </div>
          </div>
        </div>
      </div>
    </section>
  </LayoutVisiteur>
</template>

<script>
import LayoutVisiteur from "../../layout/LayoutVisiteur.vue";
import { getArticleBySlug } from "../../api/article.js";
import Commentaire from "../../components/visiteur/CommentaireVisit.vue";
import VueContentLoading from "vue-content-loading";

export default {
  name: "SelectArticle",
  components: { LayoutVisiteur, Commentaire, VueContentLoading },
  data() {
    return {
      loading: true,
      data: [],
    };
  },
  mounted() {
    getArticleBySlug(this.$route.params.slug).then((result) => {
      this.data = result.data;
      this.loading = false;
      console.log("result", result.data);
      if (result.data.error) {
        this.$router.push(`/articles`);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.content-article {
  overflow: hidden;
  p {
    display: none;
  }

  img {
    max-width: 100% !important;
    max-height: 100% !important;
  }
}
</style>
