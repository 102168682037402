<template>
  <div class="reponse mt-2 ps-3">
    <div class="bar rounded"></div>
    <!-- reponse -->
    <div class="bg-light p-3 rounded mb-3" v-for="(rep, i) in data" :key="i">
      <div>
        <span class="text-primary fw-bold">{{ rep.fname_collab }} </span>
        {{ rep.content_rep }}
      </div>
      <div
        class="text-muted fs-6 d-flex justify-content-between align-items-center"
      >
        <small>
          {{ moment(new Date(rep.date_rep), "YYYYMMDD").fromNow(true) }}
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import { getReponseByComment, setReponseView } from "../../api/reponse";
import { socket } from "../../api/socket";

export default {
  name: "ListReponseVisit",
  props: ["id_comment", "id_visiteur"],
  data() {
    return {
      data: [],
    };
  },
  mounted() {
    getReponseByComment(this.id_comment).then((result) => {
      if (this.id_visiteur == this.me.id_visiteur) {
        result.data.forEach((rep) => {
          if (!rep.view_reponse) {
            setReponseView(rep.id_rep).then(() => {
              socket.emit("send-view");
            });
          }
        });
      }
      this.data = result.data;
    });
    socket.on("arrival-comment", () => {
      getReponseByComment(this.id_comment).then((result) => {
        this.data = result.data;
      });
    });
  },
  computed: {
    me() {
      return this.$store.getters["visiteurStore/me"];
    },
  },
};
</script>
