<template>
  <section>
    <!-- <p>List des commentaires</p>
    <p>{{ data }}</p> -->
    <h4 class="text-primary fw-bold d-flex justify-content-between">
      <span>Commentaires</span><span>{{ data.length }} </span>
    </h4>
    <article>
      <!-- commentaire -->
      <div class="mb-3" v-for="(comment, i) in data" :key="i">
        <div class="text-dark">
          <div class="bg-secondary text-light rounded mb-3 p-3">
            <div>
              <span class="text-primary fw-bold">
                {{ comment.first_name_visit + " " + comment.last_name_visit }}
              </span>
              {{ comment.content_comment }}
            </div>
            <div
              class="text-muted mt-2 d-flex justify-content-between align-items-center"
            >
              <small>
                {{
                  moment(new Date(comment.date_comment), "YYYYMMDD").fromNow(
                    true
                  )
                }}</small
              >
              <div
                v-if="checkIfMine(comment.id_visiteur)"
                class="d-flex btn-actions"
                role="button"
                tabindex="0"
              >
                <div
                  class="btn-actions-sub d-flex justify-content-evenly align-items-center p-2 px-3 rounded-pill"
                >
                  <small
                    class="mx-1"
                    data-bs-toggle="modal"
                    data-bs-target="#modalModifier"
                    @click="
                      updateComment(comment.id_comment, comment.content_comment)
                    "
                    >Modifier</small
                  >
                  <small
                    data-bs-toggle="modal"
                    data-bs-target="#modalDelete"
                    @click="initDeleteComment(comment.id_comment)"
                    class="mx-1"
                    >Supprimer</small
                  >
                </div>
                <small
                  class="btn-dots d-flex justify-content-center align-items-center px-2 ms-2 rounded-pill"
                  ><IcoAction
                /></small>
              </div>
            </div>
          </div>
          <Reponse
            :id_comment="comment.id_comment"
            :id_visiteur="comment.id_visiteur"
          />
        </div>
      </div>
    </article>
    <h4 class="text-primary fw-bold">Laisser un commentaire</h4>
    <form @submit.prevent="confirm()">
      <div class="col-12 mt-3">
        <!-- <label for="inputMessage" class="form-label">Votre commentaire</label> -->
        <textarea
          class="form-control shadow"
          id="inputMessage"
          required
          rows="5"
          @focus="checkIsLoggin"
          v-model="form.content_comment"
          style="resize: none"
          placeholder="Entrez votre commentaire ici. Merci de soigner votre orthographe pour une lecture plus confortable."
        ></textarea>
      </div>

      <div class="col-12 mb-2 mt-4">
        <div
          v-if="loading.comment"
          class="ms-5 spinner-border text-warning"
          role="status"
        ></div>
        <button v-else type="submit" class="btn btn-primary">Envoyer</button>
      </div>
    </form>
    <!-- modal update  début -->
    <div
      class="modal fade"
      id="modalModifier"
      tabindex="-1"
      aria-labelledby="modalLabelModifier"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <form
          @submit.prevent="confirmUpdate"
          class="modal-content border-0 bg-light text-dark"
        >
          <div class="modal-header mx-2">
            <h4 class="modal-title text-primary" id="modalLabelModifier">
              Modifier le commentaire
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <section class="row p-2">
              <div class="col-12">
                <textarea
                  class="form-control"
                  id="inputMessage"
                  rows="4"
                  required
                  v-model="formUpdate.content_comment"
                  style="resize: none"
                ></textarea>
              </div>
            </section>
          </div>
          <div class="modal-footer mx-2">
            <button
              type="button"
              class="btn btn-secondary me-0"
              data-bs-dismiss="modal"
              ref="CloseModifier"
            >
              Annuler
            </button>
            <div
              v-if="loading.update"
              class="mx-5 spinner-border text-warning"
              role="status"
            ></div>
            <button v-else type="submit" class="btn btn-primary ms-3">
              Modifier
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- modal update fin-->

    <!-- modal delete début-->
    <div
      class="modal fade"
      id="modalDelete"
      tabindex="-1"
      aria-labelledby="modalLabelDelete"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0 bg-light text-dark">
          <div class="modal-header mx-2">
            <h4 class="modal-title text-primary" id="modalLabelDelete">
              Supprimer le commentaire
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <section class="row p-2">
              <div class="col-12">Le commentaire ne sera plus visible</div>
            </section>
          </div>
          <div class="modal-footer mx-2">
            <button
              type="button"
              class="btn btn-secondary me-0"
              data-bs-dismiss="modal"
              ref="CloseDelete"
            >
              Annuler
            </button>
            <div
              v-if="loading.delete"
              class="mx-5 spinner-border text-warning"
              role="status"
            ></div>
            <button
              v-else
              @click="deleteComment()"
              class="btn btn-primary ms-3"
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal delete fin-->
  </section>
</template>

<script>
import {
  addComment,
  getCommentByArticle,
  updateCommentById,
  deleteCommentById,
} from "../../api/comment";
import { decodeToken } from "../../utils/decodeToken";
import IcoAction from "vue-material-design-icons/DotsHorizontal.vue";
import { socket } from "../../api/socket";
import Reponse from "../../components/visiteur/ResponseVisit.vue";

export default {
  name: "ListCommentaireVisit",
  props: ["commentaires", "id_article"],
  components: {
    IcoAction,
    Reponse,
  },
  data() {
    return {
      form: {
        content_comment: "",
        id_article: this.id_article,
      },
      loading: {
        comment: false,
        update: false,
        delete: false,
      },
      data: [],
      formUpdate: { idSelectUpdate: 0, content_comment: "" },
      idDelete: 0,
    };
  },
  mounted() {
    getCommentByArticle(this.id_article).then((result) => {
      this.data = result.data;
    });

    socket.on("arrival-comment", () => {
      getCommentByArticle(this.id_article).then((result) => {
        this.data = result.data;
      });
      // var audio = new Audio(
      //   "http://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3"
      // );
      // audio.play();
      // console.log("nouveau commentaire");
    });
  },
  computed: {
    me() {
      return this.$store.getters["visiteurStore/me"];
    },
  },
  methods: {
    confirm() {
      this.loading.comment = true;
      addComment(this.form).then((result) => {
        socket.emit("change-comment", result.data);
        socket.emit("send-notif", { data: result.data, type: "COMMENT" });
        //this.$store.dispatch("articleStore/addComment", result.data);
        this.data.unshift(result.data);
        this.form.content_comment = "";
        this.loading.comment = false;
      });
      // .catch((err) => {
      //   console.log(err);
      // });
    },
    checkIsLoggin() {
      try {
        const decodeV = decodeToken(localStorage.getItem("token"));
        if (decodeV) {
          //console.log("ok visiteur");
        }
      } catch (err) {
        this.$router.push(`/se-connecter`);
      }
    },
    checkIfMine(id) {
      if (id === this.me.id_visiteur) {
        return true;
      } else {
        return false;
      }
    },
    updateComment(id, comment) {
      this.formUpdate = {
        idSelectUpdate: id,
        content_comment: comment,
      };
    },
    initDeleteComment(id) {
      this.idDelete = id;
    },
    deleteComment() {
      this.loading.delete = true;
      deleteCommentById(this.idDelete).then(() => {
        getCommentByArticle(this.id_article).then((result) => {
          this.data = result.data;
          socket.emit("change-comment");
          this.loading.delete = false;
          this.$refs.CloseDelete.click();
        });
      });
    },
    confirmUpdate() {
      this.loading.update = true;
      updateCommentById(this.formUpdate).then(() => {
        getCommentByArticle(this.id_article).then((result) => {
          this.data = result.data;
          socket.emit("change-comment");
          this.loading.update = false;
          this.$refs.CloseModifier.click();
        });
      });
    },
    showActions() {
      this.actions = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

.reponse {
  position: relative;

  .bar {
    position: absolute;
    width: 0.15rem;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $secondary;
    opacity: 0.5;
  }
}

.btn-actions {
  cursor: default;
  transition: 250ms;

  .btn-actions-sub {
    visibility: hidden;
    background-color: $primary;
    opacity: 0;
    transition: 250ms;

    small {
      transition: 250ms;
      cursor: pointer;

      &:hover {
        color: $light;
      }
    }
  }

  .btn-dots {
    cursor: pointer;
    transition: 250ms;

    &:hover {
      background-color: $primary;
      transform: scale(1.1);
    }
  }

  &:focus .btn-actions-sub {
    opacity: 1;
    visibility: visible;
  }
}
</style>
